import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatISO } from "date-fns";
import { getBalanceFieldSet } from "../../../../shared/components/balances/balanceFieldset";
import { api } from "../../../api/client";
import { CashFlowRequest, GetBalanceRequest } from "./types";

interface BalanceDataRequest {
  reportingDate?: Date;
  client: string;
}

export const fetchBalance = createAsyncThunk(
  "dashboard/balance",
  async ({ reportingDate, client }: BalanceDataRequest) => {
    let reportingPeriods: Date[] = [];
    let updateReportingPeriods = false;

    if (!reportingDate) {
      updateReportingPeriods = true;
      const response = await api.dashboard.getInitialData();
      if (response && response.data?.reportingPeriods?.length) {
        reportingPeriods = response.data.reportingPeriods;
        const initialDate = reportingPeriods[0];
        if (initialDate) {
          reportingDate = new Date(initialDate);
        }
      }
    }

    if (reportingDate) {
      const requestModel: GetBalanceRequest = {
        reportingDate: formatISO(reportingDate, { representation: "date" }),
        fields: getBalanceFieldSet(true),
      };
      const response = await api.dashboard.getBalance(requestModel);
      return {
        data: response.data,
        client: client,
        reportingDateIso: formatISO(reportingDate),
        reportingPeriods,
        updateReportingPeriods,
      };
    }

    return { data: undefined, client, reportingDateIso: undefined, reportingPeriods, updateReportingPeriods: true };
  }
);

export const fetchCashFlowData = createAsyncThunk("dashboard/cashFlow", async (cashFlowRequest: CashFlowRequest) => {
  const response = await api.dashboard.getCapCalls({ ...cashFlowRequest, descriptiveDate: undefined });
  return { data: response.data, request: cashFlowRequest };
});
