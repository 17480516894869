import { GridColDef } from "@mui/x-data-grid-premium";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { Contact } from "../../../../../api/types/settingsTypes";

interface Props {
  contacts: Contact[];
  columns: GridColDef<Contact>[];
}

export const ContactsGrid = ({ contacts, columns }: Props) => {
  return (
    <DataGrid<Contact>
      sx={{
        ".MuiDataGrid-row": {
          ".MuiDataGrid-cell": {
            outline: "none !important",

            "&:focus": {
              outline: "unset !important",
            },

            ".ntrl-memeber-action-btn": {
              visibility: "hidden",
            },
          },

          "&:hover": {
            ".MuiDataGrid-cell": {
              ".ntrl-memeber-action-btn": {
                visibility: "visible",
              },
            },
          },
        },
      }}
      getRowClassName={() => "ntrl-row"}
      style={{ border: "none" }}
      getRowId={(row) => row.userId}
      rows={contacts}
      columns={columns}
    />
  );
};

export default ContactsGrid;
