import { ConsentAgreement } from "../../shared/components/consentAgreement/types";

const requiredConsentAgreements: ConsentAgreement[] = [
  {
    type: "InvestorPortalBasicAgreement",
    version: 1,
    name: "Entrilia Privacy Policy",
    url: "https://www.entrilia.com/privacy-policy",
  },
];

export const getUnsignedGeneralAgreements = (signedAgreements: ConsentAgreement[]): ConsentAgreement[] => {
  return requiredConsentAgreements.filter(
    (agreement) =>
      !signedAgreements.some(
        (signedAgreement) => signedAgreement.type === agreement.type && signedAgreement.version === agreement.version
      )
  );
};
