import { FieldConfiguration } from "../../../../shared/reporting/api/biClient.types";
import { DescriptiveDate } from "../../../../shared/utilities/dateFilterHelper";

export interface DashboardState {
  balanceLoading: boolean;
  balanceError: string | undefined;
  balanceData: BalanceDataResponse | undefined;
  balanceInitialData: BalanceInitialData | undefined;
  balanceFilters: BalanceFiltersData;
  balanceClient: string | undefined;
  cashFlowData: CapCallOrDistribution[] | undefined;
  cashFlowRequestData: CashFlowRequest | undefined;
  cashFlowError: string | undefined;
  cashFlowLoading: boolean;
}

export interface CapCallOrDistribution {
  id: string;
  investorTitle: string;
  fundName: string;
  name: string;
  status: DistributionStatus;
  date: string;
  amount: number;
  unfunded: number;
  transactions: CapCallOrDistributionTransaction[];
}

export type DistributionStatus = "Unpaid" | "PartlyPaid" | "Paid";

export interface CapCallOrDistributionTransaction {
  id: string;
  transactionDescription: string;
  detailsDescription: string;
  impact: CapCallOrDistributionTransactionImpact;
  netAmount: number;
  periodEndingDate: string;
  investment: string;
  currency: string;
}

export type CapCallOrDistributionTransactionImpact = "None" | "Increases" | "Decreases";

export interface GetBalanceRequest {
  reportingDate: Date | string;
  fields: FieldConfiguration[];
}

interface DrillDownDataResponse {
  data: { [key: string]: string }[];
}

export interface BalanceDataResponse {
  balanceData: DrillDownDataResponse;
  fundReportingDates: Record<string, Date>;
}

export interface BalanceInitialData {
  reportingPeriods: Date[];
}

export interface BalanceFiltersData {
  investors: string[];
  funds: string[];
  reportingDateIso?: string;
  initialDateIso?: string;
}

interface CashFlowFiltersData {
  investors?: string[];
  funds?: string[];
  paymentStatuses?: DistributionStatus[];
  from?: string;
  to?: string;
  descriptiveDate?: DescriptiveDate;
}

interface PagingRequest {
  page: number;
  size: number;
  totals?: boolean;
}

export interface CashFlowRequest extends CashFlowFiltersData {
  paging: PagingRequest;
}

export const cashFlowDefaults = {
  defaultCashFlowPageSize: 8,
  cashFlowPageSizeAll: 30,
};
