import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { IconButton, Stack } from "@mui/material";
import {
  ElementSize,
  GridColDef,
  GridColumnResizeParams,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import PreviewFileDialog from "../../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { convertISODateShort } from "../../../../../shared/utilities/dateUtils";
import { useInboxStateContext } from "../../../../contexts/InboxStateContext";
import { markMessageAsRead } from "../../../../hooks/inboxState/inboxState.api";
import { usePdfPreview } from "../../../../hooks/pdfPreview";
import { useLocalization } from "../../../../hooks/useLocalization";
import { downloadAllAttachments } from "../../../../services/attachments";
import { GridColDefConfig } from "../../../../store/state/inboxStructure/types";
import { MessageActions } from "../../../../store/state/messages/actions";
import {
  getEntityFromMessage,
  getFundFromMessage,
  getRecipientsFromMessage,
} from "../../../../store/state/messages/messageHelper";
import {
  inboxGridConfigErrorSelector,
  inboxGridConfigSelector,
  markAsImportantUpdatingSelector,
  messagesSelector,
} from "../../../../store/state/messages/selectors";
import { markAsImportant, updateInboxGridConfiguration } from "../../../../store/state/messages/thunks";
import { FileDownloadInfo, Message, MessageType } from "../../../../store/state/messages/types";
import { impersonationSelector } from "../../../../store/state/user/selectors";
import { AppDispatch } from "../../../../store/store";
import { MessageLevelComponent } from "../../messageLevel/MessageLevelComponent";
import MessagePublishStatusIcon from "../MessagePublishStatusIcon";
import MessageTypeComponent from "../MessageType";
import { messageGridDefaultColumnWidth } from "./messageGridDefaults";

interface Props {
  selection: GridRowSelectionModel;
  setSelection: (values: GridRowSelectionModel) => void;
  navigateToMessage: (id: string) => void;
}

interface TextItemProps {
  emphasize: boolean;
  text: string | undefined;
  subText?: string;
}

const TextItem = ({ emphasize, text, subText }: TextItemProps) => {
  const fontWeight = emphasize ? "bold" : "normal";

  return (
    <Stack p={1} sx={{ maxWidth: "100%" }}>
      <TypographyTooltipEllipsis text={text ?? ""} typographyProps={{ fontWeight }} />
      {subText && (
        <TypographyTooltipEllipsis
          text={subText}
          typographyProps={{ variant: "caption", color: "text.secondary", fontWeight }}
        />
      )}
    </Stack>
  );
};

export const MessageGrid = ({ selection, setSelection, navigateToMessage }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { sendNotificationError } = useNotificationContext();
  const { prepareMessageForPreview } = usePdfPreview();
  const locale = useLocalization();
  const { ui } = useInboxStateContext();
  const messages = useSelector(messagesSelector);
  const messageUpdating = useSelector(markAsImportantUpdatingSelector);
  const isImpersonation = useSelector(impersonationSelector);
  const inboxGridConfigError = useSelector(inboxGridConfigErrorSelector);
  const [previewFileData, setPreviewFileData] = useState<FileDownloadInfo | undefined>(undefined);
  const [gridWidth, setGridWidth] = useState<number>(1);
  const columnsDefConfig = useSelector(inboxGridConfigSelector).columnDefinitions;

  const saveGridSettings = useDebounce(
    (columnsDef: GridColDefConfig[]) => {
      dispatch(updateInboxGridConfiguration(columnsDef));
    },
    500,
    true
  );

  const changeMessageImportance = useCallback(
    (messageId: string) => {
      const message = messages.find((msg) => msg.id === messageId);
      if (message) {
        dispatch(markAsImportant([message.id], !message.isImportant));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages, messageUpdating, dispatch]
  );

  const onDownloadAllAttachments = useCallback(
    async (message: Message) => {
      try {
        await downloadAllAttachments(message);
        if (!message.isRead) {
          await markMessageAsRead(message.id)(dispatch);
        }
      } catch {
        sendNotificationError(locale.inbox.download_files_error);
      }
    },
    [dispatch, locale.inbox.download_files_error, sendNotificationError]
  );

  const handleGridResize = (containerSize: ElementSize) => {
    setGridWidth(containerSize.width);
  };

  const getColumnWidth = useCallback(
    (field: string) => {
      const columnDefinition = columnsDefConfig.find((c) => c.field === field);
      const widthPercent = columnDefinition ? columnDefinition.width : messageGridDefaultColumnWidth[field] || 10;
      return (gridWidth * widthPercent) / (isImpersonation ? 110 : 100);
    },
    [columnsDefConfig, gridWidth, isImpersonation]
  );

  const handleColumnWidthChange = ({ colDef, width }: GridColumnResizeParams) => {
    const totalColumnsWidth = columns.reduce((acc, col) => acc + (col.width || 0), 0);
    const oldWidth = columns.find((col) => col.field === colDef.field)?.width || getColumnWidth(colDef.field) || 0;
    const impersonationColumsWidth = isImpersonation ? 100 : 0;
    const maxWidthAllowedForColumns = gridWidth - columns.length * 10 - impersonationColumsWidth;
    const setWidth =
      totalColumnsWidth - oldWidth + width > maxWidthAllowedForColumns
        ? maxWidthAllowedForColumns - (totalColumnsWidth - oldWidth)
        : width;
    const proportionalWidth = Math.round((setWidth / gridWidth) * 100);
    const colsDef = [
      ...columnsDefConfig.filter((c) => c.field !== colDef.field),
      { field: colDef.field, width: proportionalWidth },
    ];

    if (!isImpersonation) {
      saveGridSettings(cloneDeep(colsDef));
    }

    dispatch(MessageActions.UpdateInboxColumnDef(colsDef));
  };

  const showHeader = selection.length === 0 && messages.length > 0;

  const columns = useMemo<GridColDef<Message>[]>(() => {
    const cols: GridColDef<Message>[] = [
      {
        field: "isImportant",
        headerName: "",
        width: 40,
        resizable: false,
        renderCell: ({ row }) => (
          <IconButton
            sx={(theme) => ({
              color: row.isImportant ? "#FFB400" : theme.palette.text.secondary,
            })}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              if (!isImpersonation) {
                changeMessageImportance(row.id);
              }
            }}
          >
            <LabelImportantIcon />
          </IconButton>
        ),
      },
      {
        field: "messageType",
        headerName: "",
        width: 60,
        minWidth: 60,
        resizable: false,
        renderCell: ({ row }) => <MessageTypeComponent message={row} />,
      },
      {
        field: "fund",
        headerName: "Fund",
        width: getColumnWidth("fund"),
        renderCell: ({ row }) => (
          <TextItem emphasize={!row.isRead} text={getFundFromMessage(row)} subText={getEntityFromMessage(row)} />
        ),
      },
      {
        field: "recipient",
        headerName: "Investor",
        width: getColumnWidth("recipient"),
        renderCell: ({ row }) => <TextItem emphasize={!row.isRead} text={getRecipientsFromMessage(row)} />,
      },
      {
        field: "subject",
        headerName: "Name",
        width: getColumnWidth("subject"),
        renderCell: ({ row }) => <TextItem emphasize={!row.isRead} text={row.content?.subject} />,
      },
      {
        field: "categoryId",
        headerName: "Category",
        minWidth: 100,
        width: getColumnWidth("categoryId"),
        renderCell: ({ row, formattedValue }) => (
          <MessageLevelComponent level={row.messageLevel} value={formattedValue} />
        ),
      },
      {
        field: "sendDate",
        headerName: "Date",
        minWidth: 100,
        flex: 1,
        width: getColumnWidth("sendDate"),
        renderCell: ({ row, value }) => (
          <TextItem emphasize={!row.isRead} text={convertISODateShort(value.toString())} />
        ),
      },
      {
        field: "hasAttachments",
        headerName: "",
        resizable: false,
        width: 44,
        cellClassName: "attachment",
        renderCell: ({ row }) => {
          if (row.hasAttachments) {
            return (
              <IconButton
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  onDownloadAllAttachments(row);
                }}
              >
                <FileDownloadOutlinedIcon />
              </IconButton>
            );
          }

          return "";
        },
      },
    ];

    if (isImpersonation) {
      cols.splice(2, 0, {
        field: "messageState",
        minWidth: 100,
        headerName: "",
        resizable: false,
        renderCell: ({ row }) => {
          return (
            <>
              <MessagePublishStatusIcon publishingStatus={row.publishingStatus} />
              <TextItem emphasize={!row.isRead} text={row.publishingStatus} />
            </>
          );
        },
      });
    }

    return cols;
  }, [getColumnWidth, isImpersonation, changeMessageImportance, onDownloadAllAttachments]);

  const onNavigateToMessage = useCallback(
    (params: GridRowParams) => {
      if (messageUpdating) {
        return;
      }
      const message = params.row as Message;
      if (message.type === MessageType.Letter) {
        navigateToMessage(message.id);
      } else {
        prepareMessageForPreview(message, !isImpersonation)
          .then((downloadData) => {
            setPreviewFileData(downloadData);
          })
          .catch(() => {
            sendNotificationError(locale.inbox.preview_file_error);
          });
      }
    },
    [
      messageUpdating,
      navigateToMessage,
      prepareMessageForPreview,
      isImpersonation,
      sendNotificationError,
      locale.inbox.preview_file_error,
    ]
  );

  if (inboxGridConfigError) {
    return <DataLoadingFailed title="Failed to save settings" bgColor="none" sx={{ pt: 5 }} />;
  }

  return (
    <>
      <DataGrid
        sx={(theme) => ({
          ".MuiDataGrid-columnHeader": {
            color: theme.palette.text.secondary,
          },
          ".MuiDataGrid-columnSeparator--resizable": {
            color: theme.palette.divider,
          },
          ".MuiDataGrid-columnHeaders": {
            borderTop: showHeader ? 1 : 0,
            borderColor: showHeader ? theme.palette.divider : "transparent",
          },
          ".MuiDataGrid-row": {
            ".MuiDataGrid-cellCheckbox": {
              svg: {
                fontSize: "1.25rem",
              },
            },
            ".MuiDataGrid-cell": {
              outline: "none",
              borderBottomColor: theme.palette.divider,
              padding: 0,
              "&.attachment": {
                button: {
                  visibility: "hidden",
                },
              },
            },
            "&:hover, .Mui-hovered": {
              cursor: "pointer",
              ".MuiDataGrid-cell.attachment": {
                button: {
                  visibility: "visible",
                },
              },
            },
          },
        })}
        rows={messages}
        columns={columns}
        checkboxSelection
        disableColumnSorting
        loading={ui.messagesLoading || messageUpdating}
        onRowSelectionModelChange={setSelection}
        rowSelectionModel={selection}
        columnHeaderHeight={showHeader ? 40 : 0}
        onColumnWidthChange={handleColumnWidthChange}
        onRowClick={onNavigateToMessage}
        onResize={handleGridResize}
      />
      {previewFileData && (
        <PreviewFileDialog
          url={previewFileData.downloadUrl}
          fileName={previewFileData.fileName}
          documentViewSessionId={previewFileData.documentViewSessionId}
          onClose={() => setPreviewFileData(undefined)}
        />
      )}
    </>
  );
};

export default MessageGrid;
