import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { logError } from "../../../../shared/logging";
import { useLocalization } from "../../../hooks/useLocalization";
import {
  selectCashFlowData,
  selectCashFlowError,
  selectCashFlowLoading,
  selectCashFlowRequestData,
} from "../../../store/state/dashboard/selectors";
import { fetchCashFlowData } from "../../../store/state/dashboard/thunk";
import { cashFlowDefaults } from "../../../store/state/dashboard/types";
import { AppDispatch } from "../../../store/store";
import TableContainerCard from "../common/TableContainerCard";
import CashFlowsTable from "./CashFlowsTable";

const CashFlowsWidget = () => {
  const dispatch: AppDispatch = useDispatch();
  const locale = useLocalization();
  const cashFlowCapCalls = useSelector(selectCashFlowData);
  const cashFlowError = useSelector(selectCashFlowError);
  const cashFlowLoading = useSelector(selectCashFlowLoading);
  const cashFlowRequest = useSelector(selectCashFlowRequestData);

  useEffect(() => {
    if (cashFlowRequest) {
      dispatch(fetchCashFlowData({ paging: { page: 0, size: cashFlowDefaults.defaultCashFlowPageSize } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (cashFlowError) {
    logError(cashFlowError, "CashFlowWidget");
  }

  return (
    <TableContainerCard title={locale.dashboard.cashFlows.title}>
      {cashFlowCapCalls && !cashFlowError && !cashFlowLoading && (
        <CashFlowsTable cashFlowCapCalls={cashFlowCapCalls} showInvestorAndFund />
      )}
      {cashFlowLoading && <InlineLoader />}
      {cashFlowError && !cashFlowLoading && <DataLoadingFailed bgColor="none" />}
    </TableContainerCard>
  );
};

export default CashFlowsWidget;
