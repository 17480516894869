import { createSelector, Selector } from "@reduxjs/toolkit";
import { AccessPermission } from "../../../../shared/api/types";
import { distinctBy } from "../../../../shared/utilities/arrayHelper";
import { getInvestorFunds, getInvestorPermissions, hasFundPermission } from "../../../utilities/permissionsHelper";
import { RootState } from "../../store";
import { FinancialAccessCategory, InvestorPermissions } from "./types";

export const userNameSelector = (state: RootState) => state.user.userName;
export const isAuthenticatedSelector = (state: RootState) => state.user.authenticated;
export const userEmailSelector = (state: RootState) => state.user.email;

export const permissionsSelector = createSelector([(state: RootState) => state.user.permissions], (permissions) =>
  distinctBy(permissions, (p) => p.clientCode)
);
export const identitySelector = (state: RootState) => state.user.identity;
export const identityLoadingStateSelector = (state: RootState) => state.user.loading.state;
export const identityUserIdSelector = (state: RootState) => state.user.identity?.id || "";
export const identityNameSelector = (state: RootState) => state.user.identity?.name || "";
export const identityEmailSelector = (state: RootState) => state.user.identity?.email || "";
export const identityPhoneSelector = (state: RootState) => state.user.identity?.phone || "";
export const identityAvatarImageSelector = (state: RootState) => state.user.identity?.avatarImage || "";
export const impersonationSelector = (state: RootState) => state.user.isImpersonationMode;
export const clientCodeSelector = (state: RootState) => state.user.clientCode;
export const featureTogglesSelector = (state: RootState) =>
  state.user.permissions.find((p) => p.clientCode === state.user.clientCode)?.featureToggles || {};

export const investorsSelector = createSelector(
  [(state: RootState) => state.user.permissions, (state: RootState) => state.user.clientCode],
  (permissions, clientCode) =>
    permissions.filter((p) => p.clientCode === clientCode).flatMap((p) => p.investorsPermissions)
);

const financialPermissionsSelector = (categoryCode: string) =>
  createSelector(
    [(state: RootState) => state.user.permissions, (state: RootState) => state.user.clientCode],
    (permissions, clientCode) =>
      permissions
        .filter((p) => p.clientCode === clientCode)
        .flatMap((p) => p.financialPermissions.filter((fp) => fp.category.code === categoryCode))
        .flatMap((fp) => fp.investor)
  );

export const financialInvestorsSelector = (categoryCode: FinancialAccessCategory) =>
  createSelector(financialPermissionsSelector(categoryCode), (investors) => investors.map((i) => i.investorTitle));

export const financialFundsSelector = (categoryCode: FinancialAccessCategory): Selector<RootState, string[]> =>
  createSelector(financialPermissionsSelector(categoryCode), (investors) =>
    investors
      .flatMap((ip) => ip.funds.map((fi) => fi.name))
      .filter((value, index, self) => self.indexOf(value) === index)
  );

export const hasPermissionSelector = (
  state: RootState,
  clientCode: string,
  investorId: string,
  permission: AccessPermission
) => {
  const investorPermissions = getInvestorPermissions(state.user.permissions, clientCode, investorId);
  return investorPermissions.includes(permission);
};

export const hasFundPermissionSelector = (
  state: RootState,
  clientCode: string,
  investorId: string,
  fundInvestorId: string,
  permission: AccessPermission
) => hasFundPermission(state.user.permissions, clientCode, investorId, fundInvestorId, permission);

export const canEditMembersSelector = (state: RootState, clientCode: string, investorId: string) => {
  const investorFunds = getInvestorFunds(state.user.permissions, clientCode, investorId);
  return investorFunds.length > 0 && investorFunds.some((fund) => fund.permissions.includes("ManageInvestorSettings"));
};

export const workspaceInvestorSelector = (state: RootState): InvestorPermissions | undefined => {
  const investors = investorsSelector(state);
  const { selectedInvestorId } = state.user;
  if (!selectedInvestorId) {
    return investors[0];
  }

  return investors.find((i) => i.investorId === selectedInvestorId) ?? investors[0];
};

export const loadingIdentitySelector = (state: RootState) => state.user.loading.identity;
