import { createContext, PropsWithChildren, useContext } from "react";
import { defined } from "../../../shared/utilities/typeHelper";

interface Props {
  downloadingFileId?: string;
  showFilePreview: (fileId: string, preventDownload: boolean) => void;
}

const FundraisingDocumentActionsContext = createContext<Props | undefined>(undefined);

export const FundraisingDocumentActionsContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => {
  return (
    <FundraisingDocumentActionsContext.Provider value={contextValue}>
      {children}
    </FundraisingDocumentActionsContext.Provider>
  );
};

export const useFundraisingDocumentActionsContext = () =>
  defined(
    useContext(FundraisingDocumentActionsContext),
    "Attempt to use FundraisingDocumentActionsContext without provider"
  );
